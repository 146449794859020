import {BehaviorSubject, forkJoin} from 'rxjs';
import {switchMap, map, catchError} from 'rxjs/operators'
import 'regenerator-runtime/runtime';
import {
  SolaceBroker,
  parseQuery,
  dashboardService,
  widgetService,
  functionDataService,
  getServerSideFunctionRequests,
  overwriteDashboardVariables,
  authController,
  functionController,
  dashboardController,
  datasetController
} from 'smash-ui-api';

const smashClient = {
  auth: authController,
  function: functionController,
  dashboard: dashboardController,
  dataStream: new BehaviorSubject(null),
  errorStream: new BehaviorSubject([]),
  dataset: datasetController
};

let errors = [];

window.smashClient = smashClient;

window.onerror = function (message, source, lineno, colno, error) {
  errors.push({dateTime: new Date().toISOString(), ...error});
  smashClient.errorStream.next(error);
};

window.onload = function () {
  SolaceBroker.solace = window.solace;
  const params = parseQuery(decodeURIComponent(location.href));
  const requestTimestamp = Date.now();
  smashClient.auth.login(params.authToken, 'apikey', {host: HOST, vpn: VPN, requestUserInfo: false})
    .pipe(
      catchError(err => {
        throw new Error(err);
      }),
      switchMap(_ => init(params)),
      switchMap(dashboard => {
        const widgets = dashboard.dashboardState.map(ds => ds.widgetModel);
        overwriteDashboardVariables(params, dashboard.variables, widgets);
        dashboardService.setCurrentDashboard(dashboard);
        const chartWidget = dashboard.dashboardState.map(ds => ds.widgetModel).find(w => w.type === 'TRADING_VIEW');
        const widget = widgetService.createWidget(chartWidget);
        const requests = getServerSideFunctionRequests(widget.legends);
        smashClient.dataStream.next(widget);
        return functionDataService.getServerSideFunctionData(requests)
          .pipe(
            map(() => widget)
          )
      })
    )
    .subscribe(widget => {
      smashClient.dataStream.next(widget);
      smashClient.errorStream.next(errors);
      startClearErrorsInterval();
    }, error => {
      errors.push({
        type: 'Connection error',
        error: typeof error === 'object' ? JSON.stringify({
          message: error.message,
          stack: error.stack,
          timestamp: requestTimestamp
        }, null, 4) : error
      });
      smashClient.errorStream.next(errors);
    });

  import(/* webpackChunkName: "react-app" */ './App');
};

function startClearErrorsInterval() {
  setInterval(() => {
    errors = [];
    smashClient.errorStream.next(errors);
  }, 3600000 * 5); // 5 hours
}

function init(params) {
  const requestTimestamp = Date.now();
  return forkJoin(
    searchDatasets(params),
    smashClient.dashboard.getDashboardById(params.dashboard)
      .pipe(
        catchError(error => {
          errors.push({
            error: 'Loading dashboard error',
            message: error.message,
            stack: error.stack,
            timestamp: requestTimestamp
          });
          smashClient.errorStream.next(errors);
        })
      )
  ).pipe(
    map(result => {
      const datasets = result[0];
      const dashboard = result[1];
      const variables = dashboard.variables;
      for (let i = 0; i < datasets.length; i++) {
        const id = `Dataset${i + 1}DatasetId`;
        const name = `Dataset${i + 1}DatasetName`;
        Object.keys(variables).forEach(k => {
          if (variables[k].name === id) {
            variables[k].value = datasets[i].persistenceId;
          }

          if (variables[k].name === name) {
            variables[k].value = datasets[i].name;
          }
        });
      }
      return dashboard;
    })
  )

}

/**
 * Searching datasets from dataset search text from url.
 * @param params
 */
function searchDatasets(params) {
  // http://localhost:3002/?Dataset1SearchText=CDID[226844]
  const {Dataset1SearchText, Dataset2SearchText, Dataset3SearchText} = params;
  const searchTexts = [Dataset1SearchText, Dataset2SearchText, Dataset3SearchText];
  const requests = searchTexts.map(s => {
    if (s) {
      const requestTimestamp = Date.now();
      return smashClient.dataset.getDatasets(s, 1, 1)
        .pipe(
          map(r => {
            if (r[0] === undefined) {
              errors.push({
                error: 'Loading security error',
                message: `Cannot find a security for ${s}`,
                timestamp: requestTimestamp
              });
              smashClient.errorStream.next(errors);

              // makes dataset control available even if there is no matching dataset
              return {
                persistenceId: '00000000-0000-0000-0000-000000000000',
                name: ''
              };
            }
            return r[0];
          })
        );
    }
    return null;
  });
  return forkJoin(requests.filter(r => r !== null));
}
